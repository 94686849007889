/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import "./styles.css";

const LGPD = () => {
    const handleClick = () => {
        document.getElementById('lgpd').style.display = 'none';
    }
  return (
    <div id="lgpd">
        <div className="lei">
            Para personalizar conteúdo utilizamos Cookies, de acordo com nossos <span id="btn" className="pol">termos</span> ao continuar, você concorda com as condições. <button className="btna" id="fecha" onClick={handleClick}> Aceito </button>
        </div>
    </div>
  )
}

export default LGPD