import React from "react";
import "./styles.css";

import Phone from "../../assets/fone.png";
import PhoneMobile from "../../assets/mobile_fone.png";
import Logo from "../../assets/logo_topo.png";
import CTA from "../../assets/call_whatsapp.png";

const Header = () => {
  return (
    <header id="header" className="container">
      <div className="header-banner">
        <div className="header-banner-logo">
          <figure>
            <img src={Logo} alt="Logo " />
          </figure>
        </div>

        <div className="header-banner-CTA">
          <p>Fale conosco via WhatsApp e faça seu orçamento:</p>
          <figure>
            <a
              href="https://wa.me/+5511995167544?text=Gostaria%20de%20conhecer%20o%20buffet"
              target="_blank"
              rel="noreferrer"
            >
              <img src={CTA} alt="whatsapp CTA " />
            </a>
          </figure>
        </div>
      </div>

      <div className="header-tel">
        <figure>
          <a href="tel:23120253">
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? PhoneMobile
                  : Phone
              }
              alt="Telefone CTA"
            />{" "}
          </a>
        </figure>
      </div>
    </header>
  );
};

export default Header;
