import React from "react";
import "./styles.css";

import CTA from "../../components/CTA";

import Img1 from "../../assets/foto01.png";
import Img2 from "../../assets/foto2.png";
import Img3 from "../../assets/foto3.png";
import Img4 from "../../assets/foto4.png";
import Img5 from "../../assets/foto5.png";
import Img6 from "../../assets/foto6.png";

const Toys = () => {
  return (
    <div id="toys" className="container">
      <h2 className="toys-title">JÁ DEU UMA OLHADA NOS BRINQUEDOS DO NOSSO ESPAÇO:</h2>
      <h3 className="toys-subtitle">Brinquedos de Parque e Buffet são + de 18 atrações pra
crianças e adultos se divertirem!</h3>

      <div className="gallery">
        <figure>
          <img src={Img1} alt="Criança brincando" />
        </figure>
        <figure>
          <img src={Img2} alt="Criança brincando" />
        </figure>
        <figure>
          <img src={Img3} alt="Criança brincando" />
        </figure>
        <figure>
          <img src={Img4} alt="Criança brincando" />
        </figure>
        <figure>
          <img src={Img5} alt="Criança brincando" />
        </figure>
        <figure>
          <img src={Img6} alt="Criança brincando" />
        </figure>
      </div>

      <CTA id="CTA"/>
    </div>
  );
};

export default Toys;
