import React from "react";
import "./styles.css";

import Phone from "../../assets/call_fone.png";
import Wpp from "../../assets/call_whatsapp.png";

const CTA = () => {
  return (
    <div id="CTA" className="container">
      <h2 className="title">
        Fale conosco por telefone ou WhatsApp e faça seu orçamento:
      </h2>

      <div className="CTA-icons">
        <figure className="CTA-tel">
          <a href="tel:23120253">
            <img src={Phone} alt="Telefone" />
          </a>
        </figure>
        <figure className="CTA-wpp">
          <a
            href="https://wa.me/+5511995167544?text=Gostaria%20de%20conhecer%20o%20buffet"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Wpp} alt="Whatsapp" />
          </a>
        </figure>
      </div>
    </div>
  );
};

export default CTA;
