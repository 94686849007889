import React from "react";
import "./styles.css";

import Logo from "../../assets/logo_rodape.png";
import Map from "../../assets/call_mapa.png";

const Footer = () => {
  return (
    <footer id="footer" className="container">
      <div className="footer-banner">
        <div className="footer-logo">
          <figure>
            <img src={Logo} alt="Logo" />
          </figure>

          <div className="right">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.3217247843654!2d-46.19034758513481!3d-23.520928265985123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cdd83dd7745f79%3A0xb13cc8ea82a14199!2sR.%20Ant%C3%B4nio%20C%C3%A2ndido%20Vi%C3%AAira%2C%20401%20-%20Centro%2C%20Mogi%20das%20Cruzes%20-%20SP%2C%2008710-200!5e0!3m2!1spt-BR!2sbr!4v1661973060697!5m2!1spt-BR!2sbr"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Map"
            ></iframe>

            <p>
              Rua Antônio Candido Vieira, 401- Centro- Mogi das Cruzes - SP.
            </p>
            <figure>
              <img src={Map} alt="Mapa" />
            </figure>
          </div>
        </div>
      </div>

      <div className="footer-final">
        <p className="reserved">
          Star Place Buffet infantil e teens © 2022 - Todos os direitos
          reservados.
        </p>
        <p>
          Desenvolvido por{" "}
          <a href="https://pluspage.com.br" target="_blank" rel="noreferrer">
            Pluspage.com.br
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
