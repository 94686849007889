import React from "react";
import "./styles.css";

import CTA from "../../components/CTA";

import Banner from "../../assets/card_diferencial.png";
import Divisor from "../../assets/linha_divisao.png";

const Plans = () => {
  return (
    <div id="plans" className="container">
      <h2 className="plans-title">NOSSO DIFERENCIAL</h2>

      <figure className="plans-figure">
        <img src={Banner} alt="Diferenciais" />
      </figure>

      <CTA id="CTA"/>

      <div className="plans-divisor">
        <figure>
          <img src={Divisor} alt="Linha divisória" />
        </figure>
      </div>
    </div>
  );
};

export default Plans;
