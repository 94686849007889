import React from "react";
import "./styles.css";

import Gallery from "../../assets/imgs-1.png";
import CTA from "../../assets/call_whatsapp.png";

const About = () => {
  return (
    <main id="about" className="container">
      <div className="about-left">
        <h2 className="title">
          NOSSA MAIOR MOTIVAÇÃO É FAZER COM QUE O SEU EVENTO SEJA INESQUECÍVEL
        </h2>

        <h3 className="subtitle">Mais do que festa, realizamos sonhos!</h3>

        <figure>
          <a
            href="https://wa.me/+5511995167544?text=Gostaria%20de%20conhecer%20o%20buffet"
            target="_blank"
            rel="noreferrer"
          >
            <img src={CTA} alt="whatsapp CTA " />
          </a>
        </figure>
      </div>

      <div className="about-right">
        <figure>
          <img src={Gallery} alt="Festas de crianças" />
        </figure>
      </div>
    </main>
  );
};

export default About;
