import React from "react";
import "./styles.css";

import CTA from "../../components/CTA";

const Testimonials = () => {
  return (
    <div id="testimonials" className="container">
      <div className="testimonials-banner">
        <h2 className="testimonials-title">
          Assista a nossos vídeos e sinta a energia que o Star Place
          proporciona!
        </h2>

        <div className="testimonials-videos">
          <div className="video1">
            <iframe
              src="https://www.youtube.com/embed/Qr3xATHGK7k"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p>
              <strong>Festa de aniversário de 5 anos da Eloisa</strong> <br />
              com o tema A Bela e a Fera.
            </p>
          </div>

          <div className="video2">
            <iframe
              src="https://www.youtube.com/embed/ncMGnZanEyw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p>
              <strong>Festa de aniversário de 11 anos do Pietro</strong> <br />
              com o tema Fortnite.
            </p>
          </div>
        </div>
      </div>

      <CTA />
    </div>
  );
};

export default Testimonials;
