import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Footer from "./components/Footer";
import LGPD from "./components/LGPD";

import { 
  Header,
  About,
  Testimonials,
  Plans,
  Toys
} from "./pages";

function App() {
  return (
    <div className="App">
      <LGPD />
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <About />
              <Testimonials />
              <Plans />
              <Toys />
            </>
          }>      
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
